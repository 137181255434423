/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * StudyVibes REST API
 * Welcome to StudyVibes REST API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AccountGoogleCallbackRetrieveParams,
  Course,
  CourseSelection,
  CourseSelectionUpdate,
  EmailLogin,
  GoogleLogin,
  GroupedSubject,
  JWTSerializerWithUserInfo,
  PaginatedQuestionAttemptList,
  PaginatedQuestionReadList,
  PaginatedQuestionReportListList,
  PaginatedRequestTutoringReadList,
  PaginatedStudentList,
  PaginatedTutorList,
  PaginatedTutorProfileReadList,
  PatchedCourse,
  PatchedCourseSelectionUpdate,
  PatchedQuestion,
  PatchedQuestionAttemptUpdate,
  PatchedRequestTutoringCreate,
  PatchedStudent,
  PatchedSubtopic,
  PatchedTopic,
  PatchedTutorProfileUpdate,
  PatchedTutorUpdate,
  Question,
  QuestionAttempt,
  QuestionAttemptCreate,
  QuestionBankAttemptListParams,
  QuestionBankCourseListParams,
  QuestionBankGroupedSubjectsListParams,
  QuestionBankGroupedSubjectsRetrieveParams,
  QuestionBankQuestionListParams,
  QuestionBankReportListParams,
  QuestionBankSubjectListParams,
  QuestionBankSubjectRetrieveParams,
  QuestionBankSubtopicListParams,
  QuestionBankTopicListParams,
  QuestionReport,
  QuestionSubjectOverview,
  RequestTutoringCreate,
  RequestTutoringRead,
  Student,
  Subject,
  Subtopic,
  Token,
  TokenRefresh,
  Topic,
  Tutor,
  TutorCreate,
  TutorProfileCount,
  TutorProfileRead,
  TutoringRequestListParams,
  UsersStudentListParams,
  UsersTutorListParams,
  UsersTutorProfileListParams,
} from '../models';
import { customInstance } from '../../customInstance';
import type { ErrorType, BodyType } from '../../customInstance';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> =
  (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * 이메일 로그인
 * @summary 이메일 로그인
 */
export const accountEmailLoginCreate = (
  emailLogin: BodyType<EmailLogin>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<JWTSerializerWithUserInfo>(
    {
      url: `/account/email_login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: emailLogin,
      signal,
    },
    options,
  );
};

export const getAccountEmailLoginCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountEmailLoginCreate>>,
    TError,
    { data: BodyType<EmailLogin> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountEmailLoginCreate>>,
  TError,
  { data: BodyType<EmailLogin> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountEmailLoginCreate>>,
    { data: BodyType<EmailLogin> }
  > = (props) => {
    const { data } = props ?? {};

    return accountEmailLoginCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountEmailLoginCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountEmailLoginCreate>>
>;
export type AccountEmailLoginCreateMutationBody = BodyType<EmailLogin>;
export type AccountEmailLoginCreateMutationError = ErrorType<unknown>;

/**
 * @summary 이메일 로그인
 */
export const useAccountEmailLoginCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountEmailLoginCreate>>,
    TError,
    { data: BodyType<EmailLogin> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountEmailLoginCreate>>,
  TError,
  { data: BodyType<EmailLogin> },
  TContext
> => {
  const mutationOptions = getAccountEmailLoginCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 구글 로그인
 * @summary 구글 로그인
 */
export const accountGoogleCreate = (
  googleLogin: BodyType<GoogleLogin>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<JWTSerializerWithUserInfo>(
    {
      url: `/account/google`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: googleLogin,
      signal,
    },
    options,
  );
};

export const getAccountGoogleCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountGoogleCreate>>,
    TError,
    { data: BodyType<GoogleLogin> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountGoogleCreate>>,
  TError,
  { data: BodyType<GoogleLogin> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountGoogleCreate>>,
    { data: BodyType<GoogleLogin> }
  > = (props) => {
    const { data } = props ?? {};

    return accountGoogleCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountGoogleCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountGoogleCreate>>
>;
export type AccountGoogleCreateMutationBody = BodyType<GoogleLogin>;
export type AccountGoogleCreateMutationError = ErrorType<unknown>;

/**
 * @summary 구글 로그인
 */
export const useAccountGoogleCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountGoogleCreate>>,
    TError,
    { data: BodyType<GoogleLogin> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountGoogleCreate>>,
  TError,
  { data: BodyType<GoogleLogin> },
  TContext
> => {
  const mutationOptions = getAccountGoogleCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 구글 콜백 로그인
 * @summary 구글 콜백 로그인
 */
export const accountGoogleCallbackRetrieve = (
  params: AccountGoogleCallbackRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Token>(
    { url: `/account/google/callback`, method: 'GET', params, signal },
    options,
  );
};

export const getAccountGoogleCallbackRetrieveQueryKey = (
  params: AccountGoogleCallbackRetrieveParams,
) => {
  return [`/account/google/callback`, ...(params ? [params] : [])] as const;
};

export const getAccountGoogleCallbackRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAccountGoogleCallbackRetrieveQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>> = ({
    signal,
  }) => accountGoogleCallbackRetrieve(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountGoogleCallbackRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>
>;
export type AccountGoogleCallbackRetrieveQueryError = ErrorType<unknown>;

export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 구글 콜백 로그인
 */

export function useAccountGoogleCallbackRetrieve<
  TData = Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>,
  TError = ErrorType<unknown>,
>(
  params: AccountGoogleCallbackRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof accountGoogleCallbackRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAccountGoogleCallbackRetrieveQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const accountTokenRefreshCreate = (
  tokenRefresh: BodyType<NonReadonly<TokenRefresh>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TokenRefresh>(
    {
      url: `/account/token/refresh`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tokenRefresh,
      signal,
    },
    options,
  );
};

export const getAccountTokenRefreshCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
    TError,
    { data: BodyType<NonReadonly<TokenRefresh>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
  TError,
  { data: BodyType<NonReadonly<TokenRefresh>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
    { data: BodyType<NonReadonly<TokenRefresh>> }
  > = (props) => {
    const { data } = props ?? {};

    return accountTokenRefreshCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountTokenRefreshCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountTokenRefreshCreate>>
>;
export type AccountTokenRefreshCreateMutationBody = BodyType<NonReadonly<TokenRefresh>>;
export type AccountTokenRefreshCreateMutationError = ErrorType<unknown>;

export const useAccountTokenRefreshCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
    TError,
    { data: BodyType<NonReadonly<TokenRefresh>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountTokenRefreshCreate>>,
  TError,
  { data: BodyType<NonReadonly<TokenRefresh>> },
  TContext
> => {
  const mutationOptions = getAccountTokenRefreshCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankAttemptList = (
  params?: QuestionBankAttemptListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQuestionAttemptList>(
    { url: `/question_bank/attempt`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankAttemptListQueryKey = (params?: QuestionBankAttemptListParams) => {
  return [`/question_bank/attempt`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankAttemptListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankAttemptListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankAttemptList>>> = ({
    signal,
  }) => questionBankAttemptList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankAttemptList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankAttemptListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptList>>
>;
export type QuestionBankAttemptListQueryError = ErrorType<unknown>;

export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankAttemptListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankAttemptList<
  TData = Awaited<ReturnType<typeof questionBankAttemptList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankAttemptListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankAttemptListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 새로운 문제 시도 생성
 */
export const questionBankAttemptCreate = (
  questionAttemptCreate: BodyType<QuestionAttemptCreate>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionAttempt>(
    {
      url: `/question_bank/attempt`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: questionAttemptCreate,
      signal,
    },
    options,
  );
};

export const getQuestionBankAttemptCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankAttemptCreate>>,
    TError,
    { data: BodyType<QuestionAttemptCreate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankAttemptCreate>>,
  TError,
  { data: BodyType<QuestionAttemptCreate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankAttemptCreate>>,
    { data: BodyType<QuestionAttemptCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankAttemptCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankAttemptCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptCreate>>
>;
export type QuestionBankAttemptCreateMutationBody = BodyType<QuestionAttemptCreate>;
export type QuestionBankAttemptCreateMutationError = ErrorType<unknown>;

export const useQuestionBankAttemptCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankAttemptCreate>>,
    TError,
    { data: BodyType<QuestionAttemptCreate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankAttemptCreate>>,
  TError,
  { data: BodyType<QuestionAttemptCreate> },
  TContext
> => {
  const mutationOptions = getQuestionBankAttemptCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankAttemptRetrieve = (
  questionId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionAttempt>(
    { url: `/question_bank/attempt/${questionId}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankAttemptRetrieveQueryKey = (questionId: number) => {
  return [`/question_bank/attempt/${questionId}`] as const;
};

export const getQuestionBankAttemptRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankAttemptRetrieveQueryKey(questionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>> = ({
    signal,
  }) => questionBankAttemptRetrieve(questionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!questionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankAttemptRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptRetrieve>>
>;
export type QuestionBankAttemptRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankAttemptRetrieve<
  TData = Awaited<ReturnType<typeof questionBankAttemptRetrieve>>,
  TError = ErrorType<unknown>,
>(
  questionId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankAttemptRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankAttemptRetrieveQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 기존 시도 업데이트
 */
export const questionBankAttemptPartialUpdate = (
  questionId: number,
  patchedQuestionAttemptUpdate: BodyType<PatchedQuestionAttemptUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<QuestionAttempt>(
    {
      url: `/question_bank/attempt/${questionId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedQuestionAttemptUpdate,
    },
    options,
  );
};

export const getQuestionBankAttemptPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
    TError,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
  TError,
  { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> }
  > = (props) => {
    const { questionId, data } = props ?? {};

    return questionBankAttemptPartialUpdate(questionId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankAttemptPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>
>;
export type QuestionBankAttemptPartialUpdateMutationBody = BodyType<PatchedQuestionAttemptUpdate>;
export type QuestionBankAttemptPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankAttemptPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
    TError,
    { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankAttemptPartialUpdate>>,
  TError,
  { questionId: number; data: BodyType<PatchedQuestionAttemptUpdate> },
  TContext
> => {
  const mutationOptions = getQuestionBankAttemptPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankCourseList = (
  params?: QuestionBankCourseListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Course[]>(
    { url: `/question_bank/course`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankCourseListQueryKey = (params?: QuestionBankCourseListParams) => {
  return [`/question_bank/course`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankCourseListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankCourseListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankCourseList>>> = ({ signal }) =>
    questionBankCourseList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankCourseList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankCourseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankCourseList>>
>;
export type QuestionBankCourseListQueryError = ErrorType<unknown>;

export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankCourseListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankCourseList<
  TData = Awaited<ReturnType<typeof questionBankCourseList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankCourseListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankCourseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankCourseRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Course>(
    { url: `/question_bank/course/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankCourseRetrieveQueryKey = (id: number) => {
  return [`/question_bank/course/${id}`] as const;
};

export const getQuestionBankCourseRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankCourseRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankCourseRetrieve>>> = ({
    signal,
  }) => questionBankCourseRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankCourseRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankCourseRetrieve>>
>;
export type QuestionBankCourseRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankCourseRetrieve<
  TData = Awaited<ReturnType<typeof questionBankCourseRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankCourseRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankCourseRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankCoursePartialUpdate = (
  id: number,
  patchedCourse: BodyType<NonReadonly<PatchedCourse>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Course>(
    {
      url: `/question_bank/course/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedCourse,
    },
    options,
  );
};

export const getQuestionBankCoursePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankCoursePartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankCoursePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>
>;
export type QuestionBankCoursePartialUpdateMutationBody = BodyType<NonReadonly<PatchedCourse>>;
export type QuestionBankCoursePartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankCoursePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankCoursePartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedCourse>> },
  TContext
> => {
  const mutationOptions = getQuestionBankCoursePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Subject 별 course_name 기준 그룹화 리스트
 */
export const questionBankGroupedSubjectsList = (
  params?: QuestionBankGroupedSubjectsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<GroupedSubject[]>(
    { url: `/question_bank/grouped_subjects`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankGroupedSubjectsListQueryKey = (
  params?: QuestionBankGroupedSubjectsListParams,
) => {
  return [`/question_bank/grouped_subjects`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankGroupedSubjectsListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankGroupedSubjectsListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>> = ({
    signal,
  }) => questionBankGroupedSubjectsList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankGroupedSubjectsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>
>;
export type QuestionBankGroupedSubjectsListQueryError = ErrorType<unknown>;

export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankGroupedSubjectsListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Subject 별 course_name 기준 그룹화 리스트
 */

export function useQuestionBankGroupedSubjectsList<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankGroupedSubjectsListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankGroupedSubjectsList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankGroupedSubjectsListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Subject 별 course_name 기준 그룹화
 */
export const questionBankGroupedSubjectsRetrieve = (
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<GroupedSubject>(
    { url: `/question_bank/grouped_subjects/${id}`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankGroupedSubjectsRetrieveQueryKey = (
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
) => {
  return [`/question_bank/grouped_subjects/${id}`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankGroupedSubjectsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getQuestionBankGroupedSubjectsRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>> = ({
    signal,
  }) => questionBankGroupedSubjectsRetrieve(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankGroupedSubjectsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>
>;
export type QuestionBankGroupedSubjectsRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: undefined | QuestionBankGroupedSubjectsRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Subject 별 course_name 기준 그룹화
 */

export function useQuestionBankGroupedSubjectsRetrieve<
  TData = Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankGroupedSubjectsRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankGroupedSubjectsRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankGroupedSubjectsRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 문제 리스트
 */
export const questionBankQuestionList = (
  params?: QuestionBankQuestionListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQuestionReadList>(
    { url: `/question_bank/question`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankQuestionListQueryKey = (params?: QuestionBankQuestionListParams) => {
  return [`/question_bank/question`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankQuestionListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankQuestionListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankQuestionList>>> = ({
    signal,
  }) => questionBankQuestionList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankQuestionList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankQuestionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionList>>
>;
export type QuestionBankQuestionListQueryError = ErrorType<unknown>;

export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankQuestionListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankQuestionList<
  TData = Awaited<ReturnType<typeof questionBankQuestionList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankQuestionListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankQuestionListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankQuestionCreate = (
  question: BodyType<NonReadonly<Question>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Question>(
    {
      url: `/question_bank/question`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: question,
      signal,
    },
    options,
  );
};

export const getQuestionBankQuestionCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankQuestionCreate>>,
    TError,
    { data: BodyType<NonReadonly<Question>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankQuestionCreate>>,
  TError,
  { data: BodyType<NonReadonly<Question>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankQuestionCreate>>,
    { data: BodyType<NonReadonly<Question>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankQuestionCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankQuestionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionCreate>>
>;
export type QuestionBankQuestionCreateMutationBody = BodyType<NonReadonly<Question>>;
export type QuestionBankQuestionCreateMutationError = ErrorType<unknown>;

export const useQuestionBankQuestionCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankQuestionCreate>>,
    TError,
    { data: BodyType<NonReadonly<Question>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankQuestionCreate>>,
  TError,
  { data: BodyType<NonReadonly<Question>> },
  TContext
> => {
  const mutationOptions = getQuestionBankQuestionCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankQuestionRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Question>(
    { url: `/question_bank/question/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankQuestionRetrieveQueryKey = (id: number) => {
  return [`/question_bank/question/${id}`] as const;
};

export const getQuestionBankQuestionRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankQuestionRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>> = ({
    signal,
  }) => questionBankQuestionRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankQuestionRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionRetrieve>>
>;
export type QuestionBankQuestionRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankQuestionRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankQuestionRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankQuestionRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankQuestionPartialUpdate = (
  id: number,
  patchedQuestion: BodyType<NonReadonly<PatchedQuestion>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Question>(
    {
      url: `/question_bank/question/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedQuestion,
    },
    options,
  );
};

export const getQuestionBankQuestionPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankQuestionPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankQuestionPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>
>;
export type QuestionBankQuestionPartialUpdateMutationBody = BodyType<NonReadonly<PatchedQuestion>>;
export type QuestionBankQuestionPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankQuestionPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankQuestionPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedQuestion>> },
  TContext
> => {
  const mutationOptions = getQuestionBankQuestionPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankQuestionDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/question/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankQuestionDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankQuestionDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankQuestionDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionDestroy>>
>;

export type QuestionBankQuestionDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankQuestionDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankQuestionDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getQuestionBankQuestionDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * Course 별 총 문제 수 (topic, subtopic), 내가 푼 문제 수
 * @summary 총 문제 수 확인
 */
export const questionBankQuestionStatusCountRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionSubjectOverview>(
    { url: `/question_bank/question/${id}/status-count`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankQuestionStatusCountRetrieveQueryKey = (id: number) => {
  return [`/question_bank/question/${id}/status-count`] as const;
};

export const getQuestionBankQuestionStatusCountRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankQuestionStatusCountRetrieveQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>
  > = ({ signal }) => questionBankQuestionStatusCountRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankQuestionStatusCountRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>
>;
export type QuestionBankQuestionStatusCountRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 총 문제 수 확인
 */

export function useQuestionBankQuestionStatusCountRetrieve<
  TData = Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof questionBankQuestionStatusCountRetrieve>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankQuestionStatusCountRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 문제 오류 신고 목록 조회 (관리자)
 */
export const questionBankReportList = (
  params?: QuestionBankReportListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedQuestionReportListList>(
    { url: `/question_bank/report`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankReportListQueryKey = (params?: QuestionBankReportListParams) => {
  return [`/question_bank/report`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankReportListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankReportListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankReportList>>> = ({ signal }) =>
    questionBankReportList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankReportList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankReportListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankReportList>>
>;
export type QuestionBankReportListQueryError = ErrorType<unknown>;

export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankReportListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankReportList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankReportList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 문제 오류 신고 목록 조회 (관리자)
 */

export function useQuestionBankReportList<
  TData = Awaited<ReturnType<typeof questionBankReportList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankReportListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankReportList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankReportListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 문제 오류 신고 생성 (사용자)
 */
export const questionBankReportCreate = (
  questionReport: BodyType<NonReadonly<QuestionReport>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<QuestionReport>(
    {
      url: `/question_bank/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: questionReport,
      signal,
    },
    options,
  );
};

export const getQuestionBankReportCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankReportCreate>>,
    TError,
    { data: BodyType<NonReadonly<QuestionReport>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankReportCreate>>,
  TError,
  { data: BodyType<NonReadonly<QuestionReport>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankReportCreate>>,
    { data: BodyType<NonReadonly<QuestionReport>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankReportCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankReportCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankReportCreate>>
>;
export type QuestionBankReportCreateMutationBody = BodyType<NonReadonly<QuestionReport>>;
export type QuestionBankReportCreateMutationError = ErrorType<unknown>;

/**
 * @summary 문제 오류 신고 생성 (사용자)
 */
export const useQuestionBankReportCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankReportCreate>>,
    TError,
    { data: BodyType<NonReadonly<QuestionReport>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankReportCreate>>,
  TError,
  { data: BodyType<NonReadonly<QuestionReport>> },
  TContext
> => {
  const mutationOptions = getQuestionBankReportCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankReportDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/report/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankReportDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankReportDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankReportDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankReportDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankReportDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankReportDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankReportDestroy>>
>;

export type QuestionBankReportDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankReportDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankReportDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankReportDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getQuestionBankReportDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 선택된 course 리스트 (프론트엔드 내 로직 필요)
 * @summary 선택된 course 리스트
 */
export const questionBankSelectedCourseList = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<CourseSelection[]>(
    { url: `/question_bank/selected_course`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankSelectedCourseListQueryKey = () => {
  return [`/question_bank/selected_course`] as const;
};

export const getQuestionBankSelectedCourseListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSelectedCourseListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSelectedCourseList>>> = ({
    signal,
  }) => questionBankSelectedCourseList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankSelectedCourseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSelectedCourseList>>
>;
export type QuestionBankSelectedCourseListQueryError = ErrorType<unknown>;

export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 선택된 course 리스트
 */

export function useQuestionBankSelectedCourseList<
  TData = Awaited<ReturnType<typeof questionBankSelectedCourseList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof questionBankSelectedCourseList>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankSelectedCourseListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * course 선택 유무 업데이트 (프론트엔드 내 로직 필요)
 * @summary course 선택 유무
 */
export const questionBankSelectedCourseSelectPartialUpdate = (
  patchedCourseSelectionUpdate: BodyType<PatchedCourseSelectionUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CourseSelectionUpdate>(
    {
      url: `/question_bank/selected_course/select`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedCourseSelectionUpdate,
    },
    options,
  );
};

export const getQuestionBankSelectedCourseSelectPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
    TError,
    { data: BodyType<PatchedCourseSelectionUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
  TError,
  { data: BodyType<PatchedCourseSelectionUpdate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
    { data: BodyType<PatchedCourseSelectionUpdate> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankSelectedCourseSelectPartialUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankSelectedCourseSelectPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>
>;
export type QuestionBankSelectedCourseSelectPartialUpdateMutationBody =
  BodyType<PatchedCourseSelectionUpdate>;
export type QuestionBankSelectedCourseSelectPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary course 선택 유무
 */
export const useQuestionBankSelectedCourseSelectPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
    TError,
    { data: BodyType<PatchedCourseSelectionUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankSelectedCourseSelectPartialUpdate>>,
  TError,
  { data: BodyType<PatchedCourseSelectionUpdate> },
  TContext
> => {
  const mutationOptions = getQuestionBankSelectedCourseSelectPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubjectList = (
  params?: QuestionBankSubjectListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subject[]>(
    { url: `/question_bank/subject`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankSubjectListQueryKey = (params?: QuestionBankSubjectListParams) => {
  return [`/question_bank/subject`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankSubjectListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubjectListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubjectList>>> = ({
    signal,
  }) => questionBankSubjectList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubjectList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankSubjectListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubjectList>>
>;
export type QuestionBankSubjectListQueryError = ErrorType<unknown>;

export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankSubjectListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankSubjectList<
  TData = Awaited<ReturnType<typeof questionBankSubjectList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubjectListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankSubjectListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubjectRetrieve = (
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subject>(
    { url: `/question_bank/subject/${id}`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankSubjectRetrieveQueryKey = (
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
) => {
  return [`/question_bank/subject/${id}`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankSubjectRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubjectRetrieveQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>> = ({
    signal,
  }) => questionBankSubjectRetrieve(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankSubjectRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubjectRetrieve>>
>;
export type QuestionBankSubjectRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params: undefined | QuestionBankSubjectRetrieveParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankSubjectRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubjectRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  params?: QuestionBankSubjectRetrieveParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubjectRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankSubjectRetrieveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubtopicList = (
  params?: QuestionBankSubtopicListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subtopic[]>(
    { url: `/question_bank/subtopic`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankSubtopicListQueryKey = (params?: QuestionBankSubtopicListParams) => {
  return [`/question_bank/subtopic`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankSubtopicListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubtopicListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubtopicList>>> = ({
    signal,
  }) => questionBankSubtopicList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubtopicList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankSubtopicListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicList>>
>;
export type QuestionBankSubtopicListQueryError = ErrorType<unknown>;

export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankSubtopicListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankSubtopicList<
  TData = Awaited<ReturnType<typeof questionBankSubtopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankSubtopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankSubtopicListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubtopicCreate = (
  subtopic: BodyType<NonReadonly<Subtopic>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subtopic>(
    {
      url: `/question_bank/subtopic`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subtopic,
      signal,
    },
    options,
  );
};

export const getQuestionBankSubtopicCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
    TError,
    { data: BodyType<NonReadonly<Subtopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
  TError,
  { data: BodyType<NonReadonly<Subtopic>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
    { data: BodyType<NonReadonly<Subtopic>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankSubtopicCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankSubtopicCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicCreate>>
>;
export type QuestionBankSubtopicCreateMutationBody = BodyType<NonReadonly<Subtopic>>;
export type QuestionBankSubtopicCreateMutationError = ErrorType<unknown>;

export const useQuestionBankSubtopicCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
    TError,
    { data: BodyType<NonReadonly<Subtopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankSubtopicCreate>>,
  TError,
  { data: BodyType<NonReadonly<Subtopic>> },
  TContext
> => {
  const mutationOptions = getQuestionBankSubtopicCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubtopicRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Subtopic>(
    { url: `/question_bank/subtopic/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankSubtopicRetrieveQueryKey = (id: number) => {
  return [`/question_bank/subtopic/${id}`] as const;
};

export const getQuestionBankSubtopicRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankSubtopicRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>> = ({
    signal,
  }) => questionBankSubtopicRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankSubtopicRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>
>;
export type QuestionBankSubtopicRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankSubtopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankSubtopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankSubtopicRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankSubtopicPartialUpdate = (
  id: number,
  patchedSubtopic: BodyType<NonReadonly<PatchedSubtopic>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Subtopic>(
    {
      url: `/question_bank/subtopic/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSubtopic,
    },
    options,
  );
};

export const getQuestionBankSubtopicPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankSubtopicPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankSubtopicPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>
>;
export type QuestionBankSubtopicPartialUpdateMutationBody = BodyType<NonReadonly<PatchedSubtopic>>;
export type QuestionBankSubtopicPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankSubtopicPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankSubtopicPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedSubtopic>> },
  TContext
> => {
  const mutationOptions = getQuestionBankSubtopicPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankSubtopicDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/subtopic/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankSubtopicDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankSubtopicDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankSubtopicDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankSubtopicDestroy>>
>;

export type QuestionBankSubtopicDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankSubtopicDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankSubtopicDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getQuestionBankSubtopicDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankTopicList = (
  params?: QuestionBankTopicListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Topic[]>(
    { url: `/question_bank/topic`, method: 'GET', params, signal },
    options,
  );
};

export const getQuestionBankTopicListQueryKey = (params?: QuestionBankTopicListParams) => {
  return [`/question_bank/topic`, ...(params ? [params] : [])] as const;
};

export const getQuestionBankTopicListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankTopicListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankTopicList>>> = ({ signal }) =>
    questionBankTopicList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankTopicList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankTopicListQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicList>>
>;
export type QuestionBankTopicListQueryError = ErrorType<unknown>;

export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | QuestionBankTopicListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankTopicList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankTopicList<
  TData = Awaited<ReturnType<typeof questionBankTopicList>>,
  TError = ErrorType<unknown>,
>(
  params?: QuestionBankTopicListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankTopicListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankTopicCreate = (
  topic: BodyType<NonReadonly<Topic>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Topic>(
    {
      url: `/question_bank/topic`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: topic,
      signal,
    },
    options,
  );
};

export const getQuestionBankTopicCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankTopicCreate>>,
    TError,
    { data: BodyType<NonReadonly<Topic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankTopicCreate>>,
  TError,
  { data: BodyType<NonReadonly<Topic>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankTopicCreate>>,
    { data: BodyType<NonReadonly<Topic>> }
  > = (props) => {
    const { data } = props ?? {};

    return questionBankTopicCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankTopicCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicCreate>>
>;
export type QuestionBankTopicCreateMutationBody = BodyType<NonReadonly<Topic>>;
export type QuestionBankTopicCreateMutationError = ErrorType<unknown>;

export const useQuestionBankTopicCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankTopicCreate>>,
    TError,
    { data: BodyType<NonReadonly<Topic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankTopicCreate>>,
  TError,
  { data: BodyType<NonReadonly<Topic>> },
  TContext
> => {
  const mutationOptions = getQuestionBankTopicCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankTopicRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Topic>(
    { url: `/question_bank/topic/${id}`, method: 'GET', signal },
    options,
  );
};

export const getQuestionBankTopicRetrieveQueryKey = (id: number) => {
  return [`/question_bank/topic/${id}`] as const;
};

export const getQuestionBankTopicRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuestionBankTopicRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionBankTopicRetrieve>>> = ({
    signal,
  }) => questionBankTopicRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type QuestionBankTopicRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicRetrieve>>
>;
export type QuestionBankTopicRetrieveQueryError = ErrorType<unknown>;

export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useQuestionBankTopicRetrieve<
  TData = Awaited<ReturnType<typeof questionBankTopicRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof questionBankTopicRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getQuestionBankTopicRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const questionBankTopicPartialUpdate = (
  id: number,
  patchedTopic: BodyType<NonReadonly<PatchedTopic>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Topic>(
    {
      url: `/question_bank/topic/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTopic,
    },
    options,
  );
};

export const getQuestionBankTopicPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return questionBankTopicPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankTopicPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>
>;
export type QuestionBankTopicPartialUpdateMutationBody = BodyType<NonReadonly<PatchedTopic>>;
export type QuestionBankTopicPartialUpdateMutationError = ErrorType<unknown>;

export const useQuestionBankTopicPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankTopicPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedTopic>> },
  TContext
> => {
  const mutationOptions = getQuestionBankTopicPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const questionBankTopicDestroy = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/question_bank/topic/${id}`, method: 'DELETE' }, options);
};

export const getQuestionBankTopicDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankTopicDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionBankTopicDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionBankTopicDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return questionBankTopicDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type QuestionBankTopicDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof questionBankTopicDestroy>>
>;

export type QuestionBankTopicDestroyMutationError = ErrorType<unknown>;

export const useQuestionBankTopicDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionBankTopicDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof questionBankTopicDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getQuestionBankTopicDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 튜터링 요청 리스트
 */
export const tutoringRequestList = (
  params?: TutoringRequestListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedRequestTutoringReadList>(
    { url: `/tutoring/request`, method: 'GET', params, signal },
    options,
  );
};

export const getTutoringRequestListQueryKey = (params?: TutoringRequestListParams) => {
  return [`/tutoring/request`, ...(params ? [params] : [])] as const;
};

export const getTutoringRequestListQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRequestListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRequestList>>> = ({ signal }) =>
    tutoringRequestList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRequestList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type TutoringRequestListQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestList>>
>;
export type TutoringRequestListQueryError = ErrorType<unknown>;

export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | TutoringRequestListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useTutoringRequestList<
  TData = Awaited<ReturnType<typeof tutoringRequestList>>,
  TError = ErrorType<unknown>,
>(
  params?: TutoringRequestListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getTutoringRequestListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 새로운 튜터링 요청 생성
 */
export const tutoringRequestCreate = (
  requestTutoringCreate: BodyType<NonReadonly<RequestTutoringCreate>>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RequestTutoringCreate>(
    {
      url: `/tutoring/request`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestTutoringCreate,
      signal,
    },
    options,
  );
};

export const getTutoringRequestCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tutoringRequestCreate>>,
    TError,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tutoringRequestCreate>>,
  TError,
  { data: BodyType<NonReadonly<RequestTutoringCreate>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringRequestCreate>>,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> }
  > = (props) => {
    const { data } = props ?? {};

    return tutoringRequestCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TutoringRequestCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestCreate>>
>;
export type TutoringRequestCreateMutationBody = BodyType<NonReadonly<RequestTutoringCreate>>;
export type TutoringRequestCreateMutationError = ErrorType<unknown>;

export const useTutoringRequestCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tutoringRequestCreate>>,
    TError,
    { data: BodyType<NonReadonly<RequestTutoringCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof tutoringRequestCreate>>,
  TError,
  { data: BodyType<NonReadonly<RequestTutoringCreate>> },
  TContext
> => {
  const mutationOptions = getTutoringRequestCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * 특정 튜터링 요청 상세 정보
 */
export const tutoringRequestRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<RequestTutoringRead>(
    { url: `/tutoring/request/${id}`, method: 'GET', signal },
    options,
  );
};

export const getTutoringRequestRetrieveQueryKey = (id: number) => {
  return [`/tutoring/request/${id}`] as const;
};

export const getTutoringRequestRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTutoringRequestRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tutoringRequestRetrieve>>> = ({
    signal,
  }) => tutoringRequestRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type TutoringRequestRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestRetrieve>>
>;
export type TutoringRequestRetrieveQueryError = ErrorType<unknown>;

export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useTutoringRequestRetrieve<
  TData = Awaited<ReturnType<typeof tutoringRequestRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof tutoringRequestRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getTutoringRequestRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 튜터링 요청 부분 업데이트
 */
export const tutoringRequestPartialUpdate = (
  id: number,
  patchedRequestTutoringCreate: BodyType<NonReadonly<PatchedRequestTutoringCreate>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RequestTutoringRead>(
    {
      url: `/tutoring/request/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedRequestTutoringCreate,
    },
    options,
  );
};

export const getTutoringRequestPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedRequestTutoringCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedRequestTutoringCreate>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedRequestTutoringCreate>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return tutoringRequestPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TutoringRequestPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>
>;
export type TutoringRequestPartialUpdateMutationBody = BodyType<
  NonReadonly<PatchedRequestTutoringCreate>
>;
export type TutoringRequestPartialUpdateMutationError = ErrorType<unknown>;

export const useTutoringRequestPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedRequestTutoringCreate>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof tutoringRequestPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedRequestTutoringCreate>> },
  TContext
> => {
  const mutationOptions = getTutoringRequestPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usersStudentList = (
  params?: UsersStudentListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedStudentList>(
    { url: `/users/student`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersStudentListQueryKey = (params?: UsersStudentListParams) => {
  return [`/users/student`, ...(params ? [params] : [])] as const;
};

export const getUsersStudentListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersStudentListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersStudentList>>> = ({ signal }) =>
    usersStudentList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersStudentList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersStudentListQueryResult = NonNullable<Awaited<ReturnType<typeof usersStudentList>>>;
export type UsersStudentListQueryError = ErrorType<unknown>;

export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | UsersStudentListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useUsersStudentList<
  TData = Awaited<ReturnType<typeof usersStudentList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersStudentListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersStudentList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersStudentListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 특정 학생 프로필 불러오기
 */
export const usersStudentRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Student>({ url: `/users/student/${id}`, method: 'GET', signal }, options);
};

export const getUsersStudentRetrieveQueryKey = (id: number) => {
  return [`/users/student/${id}`] as const;
};

export const getUsersStudentRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersStudentRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersStudentRetrieve>>> = ({ signal }) =>
    usersStudentRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersStudentRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersStudentRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersStudentRetrieve>>
>;
export type UsersStudentRetrieveQueryError = ErrorType<unknown>;

export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersStudentRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 특정 학생 프로필 불러오기
 */

export function useUsersStudentRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersStudentRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersStudentRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 학생 프로필 수정
 */
export const usersStudentPartialUpdate = (
  id: number,
  patchedStudent: BodyType<NonReadonly<PatchedStudent>>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Student>(
    {
      url: `/users/student/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedStudent,
    },
    options,
  );
};

export const getUsersStudentPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> }
  > = (props) => {
    const { id, data } = props ?? {};

    return usersStudentPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersStudentPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersStudentPartialUpdate>>
>;
export type UsersStudentPartialUpdateMutationBody = BodyType<NonReadonly<PatchedStudent>>;
export type UsersStudentPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 학생 프로필 수정
 */
export const useUsersStudentPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
    TError,
    { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof usersStudentPartialUpdate>>,
  TError,
  { id: number; data: BodyType<NonReadonly<PatchedStudent>> },
  TContext
> => {
  const mutationOptions = getUsersStudentPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 학생 (본인) 프로필 확인
 */
export const usersStudentMeRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Student>({ url: `/users/student/me`, method: 'GET', signal }, options);
};

export const getUsersStudentMeRetrieveQueryKey = () => {
  return [`/users/student/me`] as const;
};

export const getUsersStudentMeRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersStudentMeRetrieveQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersStudentMeRetrieve>>> = ({ signal }) =>
    usersStudentMeRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersStudentMeRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersStudentMeRetrieve>>
>;
export type UsersStudentMeRetrieveQueryError = ErrorType<unknown>;

export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 학생 (본인) 프로필 확인
 */

export function useUsersStudentMeRetrieve<
  TData = Awaited<ReturnType<typeof usersStudentMeRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof usersStudentMeRetrieve>>, TError, TData>
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersStudentMeRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Staff가 관리하는 모든 튜터를 조회합니다.
 * @summary 튜터 목록 조회
 */
export const usersTutorList = (
  params?: UsersTutorListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutorList>(
    { url: `/users/tutor`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersTutorListQueryKey = (params?: UsersTutorListParams) => {
  return [`/users/tutor`, ...(params ? [params] : [])] as const;
};

export const getUsersTutorListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorList>>> = ({ signal }) =>
    usersTutorList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersTutorListQueryResult = NonNullable<Awaited<ReturnType<typeof usersTutorList>>>;
export type UsersTutorListQueryError = ErrorType<unknown>;

export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | UsersTutorListParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 튜터 목록 조회
 */

export function useUsersTutorList<
  TData = Awaited<ReturnType<typeof usersTutorList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorList>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersTutorListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Staff가 새로운 Tutor 계정을 생성합니다.
 * @summary 튜터 생성
 */
export const usersTutorCreate = (
  tutorCreate: BodyType<TutorCreate>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Tutor>(
    {
      url: `/users/tutor`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: tutorCreate,
      signal,
    },
    options,
  );
};

export const getUsersTutorCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorCreate>>,
    TError,
    { data: BodyType<TutorCreate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersTutorCreate>>,
  TError,
  { data: BodyType<TutorCreate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorCreate>>,
    { data: BodyType<TutorCreate> }
  > = (props) => {
    const { data } = props ?? {};

    return usersTutorCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersTutorCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorCreate>>
>;
export type UsersTutorCreateMutationBody = BodyType<TutorCreate>;
export type UsersTutorCreateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 생성
 */
export const useUsersTutorCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorCreate>>,
    TError,
    { data: BodyType<TutorCreate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof usersTutorCreate>>,
  TError,
  { data: BodyType<TutorCreate> },
  TContext
> => {
  const mutationOptions = getUsersTutorCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usersTutorRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<Tutor>({ url: `/users/tutor/${id}`, method: 'GET', signal }, options);
};

export const getUsersTutorRetrieveQueryKey = (id: number) => {
  return [`/users/tutor/${id}`] as const;
};

export const getUsersTutorRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorRetrieveQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorRetrieve>>> = ({ signal }) =>
    usersTutorRetrieve(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersTutorRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorRetrieve>>
>;
export type UsersTutorRetrieveQueryError = ErrorType<unknown>;

export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useUsersTutorRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersTutorRetrieve>>, TError, TData>>;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersTutorRetrieveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Staff가 Tutor의 정보를 수정합니다.
 * @summary 튜터 수정
 */
export const usersTutorPartialUpdate = (
  id: number,
  patchedTutorUpdate: BodyType<PatchedTutorUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Tutor>(
    {
      url: `/users/tutor/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutorUpdate,
    },
    options,
  );
};

export const getUsersTutorPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedTutorUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
  TError,
  { id: number; data: BodyType<PatchedTutorUpdate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
    { id: number; data: BodyType<PatchedTutorUpdate> }
  > = (props) => {
    const { id, data } = props ?? {};

    return usersTutorPartialUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersTutorPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorPartialUpdate>>
>;
export type UsersTutorPartialUpdateMutationBody = BodyType<PatchedTutorUpdate>;
export type UsersTutorPartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 수정
 */
export const useUsersTutorPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedTutorUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof usersTutorPartialUpdate>>,
  TError,
  { id: number; data: BodyType<PatchedTutorUpdate> },
  TContext
> => {
  const mutationOptions = getUsersTutorPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usersTutorDestroy = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/users/tutor/${id}`, method: 'DELETE' }, options);
};

export const getUsersTutorDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersTutorDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorDestroy>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return usersTutorDestroy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersTutorDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorDestroy>>
>;

export type UsersTutorDestroyMutationError = ErrorType<unknown>;

export const useUsersTutorDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorDestroy>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof usersTutorDestroy>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = getUsersTutorDestroyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터 검색
 */
export const usersTutorProfileList = (
  params?: UsersTutorProfileListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<PaginatedTutorProfileReadList>(
    { url: `/users/tutor_profile`, method: 'GET', params, signal },
    options,
  );
};

export const getUsersTutorProfileListQueryKey = (params?: UsersTutorProfileListParams) => {
  return [`/users/tutor_profile`, ...(params ? [params] : [])] as const;
};

export const getUsersTutorProfileListQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorProfileListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorProfileList>>> = ({ signal }) =>
    usersTutorProfileList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorProfileList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersTutorProfileListQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfileList>>
>;
export type UsersTutorProfileListQueryError = ErrorType<unknown>;

export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params: undefined | UsersTutorProfileListParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorProfileList>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 튜터 검색
 */

export function useUsersTutorProfileList<
  TData = Awaited<ReturnType<typeof usersTutorProfileList>>,
  TError = ErrorType<unknown>,
>(
  params?: UsersTutorProfileListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileList>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersTutorProfileListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 프로필 가져오기
 */
export const usersTutorProfileRetrieve = (
  userId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutorProfileRead>(
    { url: `/users/tutor_profile/${userId}`, method: 'GET', signal },
    options,
  );
};

export const getUsersTutorProfileRetrieveQueryKey = (userId: number) => {
  return [`/users/tutor_profile/${userId}`] as const;
};

export const getUsersTutorProfileRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorProfileRetrieveQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>> = ({
    signal,
  }) => usersTutorProfileRetrieve(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersTutorProfileRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfileRetrieve>>
>;
export type UsersTutorProfileRetrieveQueryError = ErrorType<unknown>;

export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
          TError,
          TData
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 튜터 프로필 가져오기
 */

export function useUsersTutorProfileRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileRetrieve>>,
  TError = ErrorType<unknown>,
>(
  userId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof usersTutorProfileRetrieve>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersTutorProfileRetrieveQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary 튜터 프로필 업데이트
 */
export const usersTutorProfilePartialUpdate = (
  userId: number,
  patchedTutorProfileUpdate: BodyType<PatchedTutorProfileUpdate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TutorProfileRead>(
    {
      url: `/users/tutor_profile/${userId}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: patchedTutorProfileUpdate,
    },
    options,
  );
};

export const getUsersTutorProfilePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
    TError,
    { userId: number; data: BodyType<PatchedTutorProfileUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
  TError,
  { userId: number; data: BodyType<PatchedTutorProfileUpdate> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
    { userId: number; data: BodyType<PatchedTutorProfileUpdate> }
  > = (props) => {
    const { userId, data } = props ?? {};

    return usersTutorProfilePartialUpdate(userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UsersTutorProfilePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>
>;
export type UsersTutorProfilePartialUpdateMutationBody = BodyType<PatchedTutorProfileUpdate>;
export type UsersTutorProfilePartialUpdateMutationError = ErrorType<unknown>;

/**
 * @summary 튜터 프로필 업데이트
 */
export const useUsersTutorProfilePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
    TError,
    { userId: number; data: BodyType<PatchedTutorProfileUpdate> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof usersTutorProfilePartialUpdate>>,
  TError,
  { userId: number; data: BodyType<PatchedTutorProfileUpdate> },
  TContext
> => {
  const mutationOptions = getUsersTutorProfilePartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 튜터 타입 별 프로필 갯수
 */
export const usersTutorProfileTutorTypeCountsRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<TutorProfileCount>(
    { url: `/users/tutor_profile/tutor-type-counts`, method: 'GET', signal },
    options,
  );
};

export const getUsersTutorProfileTutorTypeCountsRetrieveQueryKey = () => {
  return [`/users/tutor_profile/tutor-type-counts`] as const;
};

export const getUsersTutorProfileTutorTypeCountsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUsersTutorProfileTutorTypeCountsRetrieveQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>
  > = ({ signal }) => usersTutorProfileTutorTypeCountsRetrieve(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UsersTutorProfileTutorTypeCountsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>
>;
export type UsersTutorProfileTutorTypeCountsRetrieveQueryError = ErrorType<unknown>;

export function useUsersTutorProfileTutorTypeCountsRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorProfileTutorTypeCountsRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
        TError,
        TData
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUsersTutorProfileTutorTypeCountsRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary 튜터 타입 별 프로필 갯수
 */

export function useUsersTutorProfileTutorTypeCountsRetrieve<
  TData = Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof usersTutorProfileTutorTypeCountsRetrieve>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUsersTutorProfileTutorTypeCountsRetrieveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
